import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import overviewSelected from "../assets/alarm-selected.svg";
import overview from "../assets/alarm.svg";
import env from "../assets/env.svg";
import floor from "../assets/floor_map.svg";
import floorSelected from "../assets/floors-selected.svg";
import help from "../assets/help.svg";
import trnspLogo from "../assets/logo.svg";
import reportsSelected from "../assets/reports-selected.svg";
import reports from "../assets/reports.svg";
import settings from "../assets/settings.svg";
import styled from "styled-components";

const SideNav = () => {
  const [isHoveredEnv, setIsHoveredEnv] = useState(false);
  const [isHoveredEng, setIsHoveredEng] = useState(false);
  const [isHoveredStg, setIsHoveredStg] = useState(false);
  const [isHoveredMcg, setIsHoveredMcg] = useState(false);
  const [show, setShow] = useState(false);
  const [showReportsSubMenu, setShowReportsSubMenu] = useState(false);

  // Function to toggle visibility of the submenu
  const toggleReportsSubMenu = () => {
    setShowReportsSubMenu(!showReportsSubMenu);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [styles, setStyles] = useState({});
  const user = JSON.parse(sessionStorage.getItem("user"));
  let overviewBG = false;
  let liveBG = false;
  let reportBG = false;
  if (location.pathname == "/") {
    overviewBG = true;
  } else if (location.pathname == "/live") {
    liveBG = true;
  } else {
    reportBG = true;
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    if (windowWidth < 990) {
      setStyles({
        "--sidenav-display": "none",
      });
    } else {
      setStyles({
        "--sidenav-display": "block",
      });
    }
  }, [windowWidth]);
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <nav className="side-nav fw-light" style={styles}>
        <ul style={{ listStyleType: "none", padding: "20px 5px", margin: "0" }}>
          <li className="logo">
            <img src="/white-logo-fn.png" />
          </li>

          <li style={{ marginBottom: "15px" }}>
            {/* <Link to="/live"> */}
            <div>
              <div
                className="d-flex justify-content-start"
                onClick={(e) => {
                  setShow(!show);
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={env}
                  // onMouseOver={() => setIsHoveredEnv(true)}
                  // onMouseOut={() => setIsHoveredEnv(false)}
                  style={{ width: "20px", marginLeft: "10px" }}
                />

                <p
                  style={{
                    textAlign: "start",
                    color: "white",
                    marginLeft: "10%",
                  }}
                >
                  Process
                </p>

                <TiArrowSortedDown
                  style={{ color: "white", margin: "auto" }}
                  size={30}
                />
              </div>

              <div
                style={{
                  color: "white",
                  marginLeft: "20%",
                  display: show ? "block" : "none",
                }}
              >
                <Link to="live" className="hover-items">
                  <div
                    className="d-flex justify-content-start p-2"
                    style={
                      liveBG
                        ? { cursor: "pointer", backgroundColor: "#254175" }
                        : { cursor: "pointer" }
                    }
                  >
                    <img
                      src={liveBG ? floorSelected : floor}
                      // onMouseOver={() => setIsHoveredEnv(true)}
                      // onMouseOut={() => setIsHoveredEnv(false)}
                      style={{ width: "20px", marginLeft: "10px" }}
                    />
                    <p
                      style={
                        liveBG
                          ? {
                              textAlign: "start",
                              color: "white",
                              marginLeft: "10%",
                              color: "#00E0FF",
                              // marginTop: "5px",
                            }
                          : {
                              textAlign: "start",
                              color: "white",
                              marginLeft: "10%",
                              // color: "#00E0FF"
                              // marginTop: "5px",
                            }
                      }
                    >
                      Live
                    </p>
                  </div>
                </Link>

                <div
                  onClick={toggleReportsSubMenu}
                  className="d-flex justify-content-start p-2"
                  style={
                    reportBG
                      ? { cursor: "pointer", backgroundColor: "#254175" }
                      : { cursor: "pointer" }
                  }
                >
                  <img
                    src={reportBG ? reportsSelected : reports}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />
                  <p
                    style={
                      reportBG
                        ? {
                            textAlign: "start",
                            color: "white",
                            marginLeft: "10%",
                            color: "#00E0FF",
                            // marginTop: "5px",
                          }
                        : {
                            textAlign: "start",
                            color: "white",
                            marginLeft: "10%",
                            // color: "#00E0FF"
                            // marginTop: "5px",
                          }
                    }
                  >
                    Reports{" "}
                    <TiArrowSortedDown
                      style={{ color: "white", margin: "auto" }}
                    />
                  </p>
                </div>
                {showReportsSubMenu && (
                  <ul
                    style={{
                      paddingLeft: "3rem",
                      listStyleType: "none",
                      margin: "10px",
                    }}
                  >
                    <li className="p-1">
                      <Link
                        to="/reports/view"
                        className="hover-items text-light p-1"
                      >
                        View
                      </Link>
                    </li>
                    <li className="p-1">
                      <Link
                        to="/reports/export"
                        className="hover-items text-light p-1"
                      >
                        Export
                      </Link>
                    </li>
                    {/* Add more submenu items as needed */}
                  </ul>
                )}
              </div>
            </div>

            {/* </Link> */}
          </li>
          <li>
            <div
              className="d-flex justify-content-start mt-4"
              style={{ cursor: "pointer" }}
            >
              <img
                src={help}
                // onMouseOver={() => setIsHoveredEnv(true)}
                // onMouseOut={() => setIsHoveredEnv(false)}
                style={{ width: "20px", marginLeft: "10px" }}
              />
              <p
                style={{
                  textAlign: "start",
                  color: "white",
                  // marginRight: "10%",
                  marginLeft: "10%",
                  marginTop: "5px",
                }}
              >
                Help center
              </p>
            </div>
          </li>
        </ul>

        <div style={{ position: "absolute", bottom: "0", right: "10%" }}>
          {/* <h5
          style={{
            color: "white",
            textAlign: "end",
            marginTop: "-10px",
          }}
        >
          <div style={{ marginLeft: "auto", padding: "0 0 0 10px" }}>
            <img src="/white-logo-fn.png" />
          </div>
        </h5>
        <h6
          style={{
            color: "white",
            textAlign: "end",
            marginTop: "-10px",
          }}
        >
          PMS
        </h6>
        <p
          style={{
            color: "white",
            marginTop: "40px",
            textAlign: "end",
          }}
        >
          Integration Partner
        </p>
        <h5
          style={{
            color: "white",
            marginTop: "-10px",
            textAlign: "end",
          }}
        >
          YGEN Engineering Ltd
        </h5> */}
          <h6
            style={{
              textAlign: "center",
              color: "white",
              marginTop: "40px",
              fontSize: "12px",
            }}
          >
            &copy; {new Date().getFullYear()} FactoryNext | All rights reserved
            Arthor Ltd.
          </h6>
        </div>
      </nav>
    </Wrapper>
  );
};

export default SideNav;

const Wrapper = styled.section`
  .logo img {
    margin-bottom: 20px;
  }
`;
